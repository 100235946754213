<template>
    <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Orders</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-shopping-cart"></i> 
                All Orders 
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Customer's Number</th>
                                <th>Status</th>
                                <th>Payment Type</th>
                                <th>Order Amount</th>
                                <th>Delivery Fee</th>
                                <th>VAT Fee</th>
                                <th>Date</th>
                                <th>Order Items</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Customer</th>
                                <th>Customer's Number</th>
                                <th>Status</th>
                                <th>Payment Type</th>
                                <th>Order Amount</th>
                                <th>Delivery Fee</th>
                                <th>VAT Fee</th>
                                <th>Date</th>
                                <th>Order Items</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr v-for="order in auth_all_orders" :key="order.id">
                                <td>{{ order.user }} ({{ order.user_email }})</td>
                                <td>
                                    <a 
                                    :href="'tel:' + order.phone" 
                                    style="text-decoration: none; color: #0d9219 !important;">
                                        {{ order.phone }}
                                    </a>
                                </td>
                                <td>
                                    <a 
                                    @click="(form_data.delivery_status = order.delivery_status), (order_id = order.id)"
                                    data-toggle="modal" 
                                    data-target="#deliveryStatus" 
                                    class="ml-0" 
                                    style="cursor: pointer; text-decoration: none;">
                                        <i 
                                        :class="order.delivery_status == 1 ? 'pending2 ' : order.delivery_status == 2 ? 'processing ' : 'delivered '"
                                        class="ml-0 pending " 
                                        style="display: inline-flex; padding: 10px;">
                                            {{ order.delivery_status == 1 ? 'Pending' : order.delivery_status == 2 ? 'Processing' : 'Delivered'}} 
                                            <i class="fas fa-edit ml-2"></i>
                                        </i>
                                    </a>
                                </td>
                                <td>{{ order.payment_status == 0 ? 'On delivery' : 'Bank transfer' }}</td>
                                <td>&#8358;{{ order.food_fee | number_with_commas }}</td>
                                <td>&#8358;{{ order.delivery_fee | number_with_commas }}</td>
                                <td>&#8358;{{ order.vat | number_with_commas }}</td>
                                <td>{{ new Date(order.created_at).toLocaleTimeString() + ', ' + new Date(order.created_at).toDateString() }}</td>
                                <td>
                                    <a @click="view_order(order)" data-toggle="modal" data-target="#viewOrderItems" style="color: rgb(13, 146, 25); cursor: pointer;">
                                        <strong>View</strong>
                                    </a>
                                </td>
                                <!-- USE MODAL BELOW FOR MENU IMAGE LATER ON -->
                                <!-- <div class="modal fade" :id="'restaurantLogo' + restaurant.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <img :src="restaurant.logo_url ? (assets_url + '' + restaurant.logo_url) : '/assets/img/no-image-available.jpeg'" alt="" style="width: 100%;">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal fade" id="deliveryStatus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                                Delivery Status
                            </h5>
                            <button id="logout_button" type="button" data-dismiss="modal" aria-label="Close" class="close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Set order delivery status for the order below</label>
                                        <div class="styled-select">
                                            <select v-model="form_data.delivery_status">
                                                <option 
                                                value="1">
                                                    Pending
                                                </option>
                                                <option 
                                                value="2">
                                                    Processing
                                                </option>
                                                <option 
                                                value="3">
                                                    Delivered
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button id="close_edit_delivery" type="button" data-dismiss="modal" class="btn btn-secondary">
                                Cancel
                            </button>
                            <button @click.prevent="delivery_status()" class="text-white btn_1 medium"> 
                                {{ saving_text }} 
                                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 60px;'"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="viewOrderItems" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                                Order Item
                            </h5>
                            <button id="logout_button" type="button" data-dismiss="modal" aria-label="Close" class="close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Restaurant</th>
                                        <th>Food</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Restaurant</th>
                                        <th>Food</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr v-for="item in order_specific_items" :key="item.id">
                                        <td>{{ item.restaurant_name }}</td>
                                        <td>{{ item.food_name }}</td>
                                        <td>&#8358;{{ item.food_price | number_with_commas }}</td>
                                        <td>{{ item.order_count }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button id="close_edit_menu" type="button" data-dismiss="modal" class="btn btn-secondary">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="auth_all_orders.length >= 1" class="card-footer small text-muted">Updated {{ last_update }}</div>
        </div>
        <!-- /tables-->
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL, ASSETS_URL } from '@/main';
export default {
    data() {
        return {
            saving_text: "Update",
            saving_load: false,
            order_id: null,
            form_data: {
                delivery_status: null
            }
        }
    },
    methods: {
        ...mapActions([
            'fetch_all_orders',
            'fetch_vendor_restaurants',
            'fetch_all_order_items',
        ]),
        view_order(order) {
            let _this = this;
            _this.order_id = order.id;
        },
        delivery_status() {
            let _this = this;
            if(!_this.saving_load) {
                _this.saving_text = "";
                _this.saving_load = true;
                _this.axios
                    .post(`${BASE_URL}vendorupdateorderstatus`, {
                        order_id: _this.order_id,
                        delivery_status: _this.form_data.delivery_status,
                    })
                    .then(function () {
                        _this.fetch_all_orders();
                        _this.order_id = null;
                        _this.form_data.delivery_status = null;
                        _this.$swal.fire("Great!", "The delivery status has been updated successfully.", "success").then(() => {
                            document.querySelector("#close_edit_delivery").click();
                        });
                        document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;
                    })
                    .catch(function () {
                        _this.$swal.fire(
                            "Error", 
                            "There was an error in updating the delivery status. Kindly check your input and retry.", 
                            "error"
                        );
                    })
                    .then(function () {
                        _this.saving_text = "Update";
                        _this.saving_load = false;
                    });
            }
        },
        timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " years"
            : Math.floor(interval) + " year";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " months"
            : Math.floor(interval) + " month";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " days"
            : Math.floor(interval) + " day";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " hours"
            : Math.floor(interval) + " hour";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " minutes"
            : Math.floor(interval) + " minute";
        }
        return Math.floor(seconds) != 1
            ? Math.floor(seconds) + " seconds"
            : Math.floor(seconds) + " second";
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
            'auth_all_orders',
            'auth_all_order_items',
        ]),
        last_update() {
            let all_updates = [], _this = this, max = null, time_since = null, string = null;
            if (_this.auth_all_orders) {
                _this.auth_all_orders.forEach(order => {
                    all_updates.splice(all_updates.length, 0, new Date(order.updated_at).getTime());
                });
                max = Math.max(...all_updates);
                time_since = _this.timeSince(max);
                string = (time_since.indexOf('second') != -1 || time_since.indexOf('minute') != -1) ? (time_since + ' ago') : (time_since + ' ago - ' + new Date(max).toDateString() + ', ' + new Date(max).toLocaleTimeString());
                return string;
            } else {
                return null
            }
        },
        order_specific_items() {
            let _this = this;
            if (_this.auth_all_order_items && _this.order_id) {
                return _this.auth_all_order_items.filter(item => {
                    return item.order_id == _this.order_id
                });
            } 
            return [];
        },
        assets_url() {
            return ASSETS_URL;
        }
    },
    mounted() {
        document.title = 'Orders - Chops';
        window.$('#dataTable').DataTable()
        .search( '' )
        .columns().search( '' )
        .draw();
        this.fetch_all_orders();
        this.fetch_all_order_items();
        this.fetch_vendor_restaurants();
    },
}
</script>

<style scoped>
.form-group {
    text-align: left;
}
</style>