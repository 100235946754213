<template>
  <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Menus' }">Menus</router-link>
                </li>
                <li class="breadcrumb-item active">Add Menu</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="box_general padding_bottom">
            <div class="header_box version_2">
                <h2><i class="fas fa-utensils"></i>Menu info</h2>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Restaurant</label>
                        <div class="styled-select">
                            <select v-model="form_data.restaurant_id">
                                <option 
                                selected="selected" 
                                disabled="disabled" 
                                value="Select restaurant">
                                    Select restaurant
                                </option>
                                <option 
                                v-for="restaurant in auth_vendor_restaurants" 
                                :key="restaurant.id" 
                                :value="restaurant.id">
                                {{ restaurant.restaurant_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Category</label>
                        <div class="styled-select">
                            <select v-model="form_data.category_id">
                                <option 
                                selected="selected" 
                                disabled="disabled" 
                                value="Select category">
                                    Select category
                                </option>
                                <option 
                                v-for="category in all_menu_categories" 
                                :key="category.id" 
                                :value="category.id">
                                {{ category.category_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Quantity</label>
                        <input 
                        placeholder="0"
                        v-model="form_data.quantity" 
                        type="number" 
                        class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Menu type</label>
                        <div class="styled-select">
                            <select v-model="form_data.menu_type">
                                <option 
                                selected="selected" 
                                disabled="disabled" 
                                value="Select menu type">
                                    Select menu type
                                </option>
                                <option 
                                value="Breakfast">
                                Breakfast
                                </option>
                                <option 
                                value="All day">
                                All day
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Description (optional)</label>
                        <textarea
                        class="form-control"
                        style="height: 100px;" 
                        v-model="form_data.description"
                        placeholder="Enter menu description">
                        </textarea>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Item name</label>
                        <input 
                        placeholder="Enter item name"
                        v-model="form_data.food_name" 
                        type="text" 
                        class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Price (per item)</label>
                        <input 
                        placeholder="Enter price"
                        v-model="form_data.price" 
                        type="text" 
                        class="form-control">
                    </div>
                </div>
            </div>
        </div>
        <p class="add-mb-5">
            <button @click.prevent="add_menu()" class="text-white btn_1 medium"> 
                {{ saving_text }} 
                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 40px;'"></i>
            </button>
            <button @click.prevent="add_menu2()" class="ml-1 btn_1 medium gray"> 
                {{ saving_text2 }} 
                <i class="fad fa-spinner fa-spin" :style="!saving_load2 ? 'display: none;' : 'padding: 0px 60px;'"></i>
            </button>
        </p>
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL } from '@/main';
export default {
    data() {
        return {
            form_data: { 
                id: "", 
                restaurant_id: "Select restaurant", 
                category_id: "Select category", 
                quantity: "", 
                menu_type: "Select menu type", 
                description: "", 
                food_name: "", 
                price: "" 
            },
            saving_text: "Save",
            saving_text2: "Save and add new",
            saving_load: false,
            saving_load2: false,
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
            'auth_restaurant_types',
            'chops_cities',
            'auth_vendor_restaurants',
            'all_menu_categories'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_restaurant_types',
            'fetch_vendor_restaurants',
            'fetch_all_menu'
        ]),
        add_menu: function () {
            let _this = this;
            _this.saving_load ||
                ((_this.saving_text = ""),
                (_this.saving_load = true),
                _this.axios
                    .post(`${BASE_URL}postmenu2`, {
                        restaurant_id: "Select restaurant" != _this.form_data.restaurant_id ? _this.form_data.restaurant_id : "",
                        category_id: "Select category" != _this.form_data.category_id ? _this.form_data.category_id : "",
                        quantity: _this.form_data.quantity,
                        menu_type: "Select menu type" != _this.form_data.menu_type ? _this.form_data.menu_type : "",
                        description: _this.form_data.description,
                        food_name: _this.form_data.food_name,
                        price: _this.form_data.price
                    })
                    .then(function () {
                        _this.fetch_all_menu(),
                        _this.form_data.restaurant_id = "Select restaurant";
                        _this.form_data.category_id = "Select category";
                        _this.form_data.quantity = "";
                        _this.form_data.menu_type = "Select menu type";
                        _this.form_data.description = "";
                        _this.form_data.food_name = "";
                        _this.form_data.price = "";
                        _this.$swal.fire("Great!", "Menu creation successful. You'll be redirected to the menus page.", "success").then(function () {
                            _this.$router.push({ name: "Menus" });
                        });
                    })
                    .catch(function () {
                        _this.$swal.fire("Error", "Please enter correct menu details.", "error");
                    })
                    .then(function () {
                        (_this.saving_text = "Save"), (_this.saving_load = false);
                    }));
        },
        add_menu2: function () {
            let _this = this;
            _this.saving_load2 ||
                ((_this.saving_text2 = ""),
                (_this.saving_load2 = true),
                _this.axios
                    .post(`${BASE_URL}postmenu2`, {
                        restaurant_id: "Select restaurant" != _this.form_data.restaurant_id ? _this.form_data.restaurant_id : "",
                        category_id: "Select category" != _this.form_data.category_id ? _this.form_data.category_id : "",
                        quantity: _this.form_data.quantity,
                        menu_type: "Select menu type" != _this.form_data.menu_type ? _this.form_data.menu_type : "",
                        description: _this.form_data.description,
                        food_name: _this.form_data.food_name,
                        price: _this.form_data.price
                    })
                    .then(function () {
                        _this.fetch_all_menu();
                        _this.form_data.restaurant_id = "Select restaurant";
                        _this.form_data.category_id = "Select category";
                        _this.form_data.quantity = "";
                        _this.form_data.menu_type = "Select menu type";
                        _this.form_data.description = "";
                        _this.form_data.food_name = "";
                        _this.form_data.price = "";
                        _this.$swal.fire("Great!", "Menu creation successful.", "success").then(() => {
                            document.querySelector('.scroll-to-top').click();
                        });
                    })
                    .catch(function () {
                        _this.$swal.fire("Error", "Please enter correct menu details.", "error");
                    })
                    .then(function () {
                        (_this.saving_text2 = "Save and add new"), (_this.saving_load2 = false);
                    }));
        },
    },
    mounted() {
        document.title = 'Add Menu - Chops';
    }
}
</script>

<style scoped>
.add-mb-5 {
    margin-bottom: 5rem;
}
</style>