import Vue from 'vue'
import Vuex from 'vuex'
import { BASE_URL } from '@/main';
import router from '@/router';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      user_token: localStorage.getItem("user_token") || "",
      user_data: localStorage.getItem("user_data") || "",
      restaurant_types: localStorage.getItem("restaurant_types") || "",
      vendor_restaurants: localStorage.getItem("vendor_restaurants") || "",
      all_menu: localStorage.getItem("all_menu") || "",
      vendor_menu_categories: localStorage.getItem("vendor_menu_categories") || "",
      all_order_items: localStorage.getItem("all_order_items") || "",
      all_orders: localStorage.getItem("all_orders") || "",
      loading_orders: true,
      loading_restaurants: true,
      loading_menus: true,
  },
  getters: {
      chops_cities: () => {
          return [
              'Abuja',
              'Lagos',
              'Port Harcourt',
              'Enugu'
          ]
      },
      current_year: () => {
          let year = new Date().getFullYear();
          return year;
      },
      auth_all_orders: function (t, a) {
          var e = [];
          return (
              a.auth_all_order_items &&
                  t.all_orders &&
                  a.auth_all_order_items.forEach(function (a) {
                      JSON.parse(t.all_orders).forEach(function (t) {
                          t.id == a.order_id && e.push(t);
                      });
                  }),
              e
          );
      },
      auth_all_order_items: function (t, a) {
          var e = [];
          return (
              a.auth_vendor_restaurants &&
                  t.all_order_items &&
                  a.auth_vendor_restaurants.forEach(function (a) {
                      JSON.parse(t.all_order_items).forEach(function (t) {
                          parseInt(t.restaurant_id) == a.id && e.push(t);
                      });
                  }),
              e
          );
      },
      auth_vendor_menu: function (t, a) {
          var e = [];
          return (
              "" != a.auth_all_menu &&
                  "" != a.auth_vendor_restaurants &&
                  a.auth_vendor_restaurants.forEach(function (t) {
                      a.auth_all_menu.forEach(function (a) {
                          parseInt(a.restaurant_id) == t.id && e.push(a);
                      });
                  }),
              e
          );
      },
      all_menu_categories: function (t) {
          return "" != t.vendor_menu_categories ? JSON.parse(t.vendor_menu_categories).categories : "";
      },
      auth_all_menu: function (t) {
          return "" != t.all_menu ? JSON.parse(t.all_menu).allmenu : "";
      },
      auth_vendor_restaurants: function (t) {
          return "" != t.vendor_restaurants ? JSON.parse(t.vendor_restaurants) : "";
      },
      auth_user_data: function (t) {
          return "" != t.user_data ? JSON.parse(t.user_data) : "";
      },
      auth_restaurant_types: function (t) {
          return "" != t.restaurant_types ? JSON.parse(t.restaurant_types) : "";
      },
      logged_in: function (t) {
          return !(!t.user_token || !t.user_data);
      },
  },
  mutations: {
      MENUS_NOT_LOADED: (state) => {
        state.loading_menus = true
      },
      MENUS_LOADED: (state) => {
        state.loading_menus = false
      },
      RESTAURANTS_NOT_LOADED: (state) => {
        state.loading_restaurants = true
      },
      RESTAURANTS_LOADED: (state) => {
        state.loading_restaurants = false
      },
      ORDERS_NOT_LOADED: (state) => {
        state.loading_orders = true
      },
      ORDERS_LOADED: (state) => {
        state.loading_orders = false
      },
      FETCH_ALL_ORDERS: function (t, a) {
          t.all_orders = a;
      },
      FETCH_ALL_ORDER_ITEMS: function (t, a) {
          t.all_order_items = a;
      },
      FETCH_MENU_CATEGORIES: function (t, a) {
          t.vendor_menu_categories = a;
      },
      FETCH_ALL_MENU: function (t, a) {
          t.all_menu = a;
      },
      FETCH_VENDOR_RESTAURANTS: function (t, a) {
          t.vendor_restaurants = a;
      },
      FETCH_RESTAURANT_TYPES: function (t, a) {
          t.restaurant_types = a;
      },
      SUCCESSFUL_LOGIN: function (state, login_data) {
          state.user_token = login_data.success.token;
          state.user_data = login_data.user;
          router.push({ name: "Home" });
      },
      PROFILE_UPDATED: function (state, user) {
            state.user_data = user;
        },
      LOGOUT_USER: function (t) {
          (t.user_data = ""), (t.user_token = ""), (t.restaurant_types = ""), (t.vendor_restaurants = ""), (t.all_menu = ""), router.push({ name: "Login" });
      },
  },
  actions: {
      fetch_all_orders: function (t) {
          Vue.axios
              .get("".concat(BASE_URL, "fetchallorders"))
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("all_orders", JSON.stringify(e)), t.commit("FETCH_ALL_ORDERS", JSON.stringify(e));
                  t.commit('ORDERS_LOADED');
              })
              .catch(function () {})
              .then(function () {});
      },
      fetch_all_order_items: function (t) {
          Vue.axios
              .get("".concat(BASE_URL, "fetchallorderitems"))
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("all_order_items", JSON.stringify(e)), t.commit("FETCH_ALL_ORDER_ITEMS", JSON.stringify(e));
              })
              .catch(function () {})
              .then(function () {});
      },
      fetch_all_menu: function (t) {
          Vue.axios
              .post("".concat(BASE_URL, "fetchmenu"))
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("all_menu", JSON.stringify(e)), t.commit("FETCH_ALL_MENU", JSON.stringify(e));
                  t.commit('MENUS_LOADED')
              })
              .catch(function () {})
              .then(function () {});
      },
      fetch_vendor_menu_categories: function (t) {
          Vue.axios
              .post("".concat(BASE_URL, "fetchallcategories"))
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("vendor_menu_categories", JSON.stringify(e)), t.commit("FETCH_MENU_CATEGORIES", JSON.stringify(e));
              })
              .catch(function () {})
              .then(function () {});
      },
      fetch_vendor_restaurants: function (t) {
          Vue.axios
              .post("".concat(BASE_URL, "getuserrestaurants"), { user_id: t.getters.auth_user_data.id })
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("vendor_restaurants", JSON.stringify(e)), t.commit("FETCH_VENDOR_RESTAURANTS", JSON.stringify(e));
                  t.commit('RESTAURANTS_LOADED');
              })
              .catch(function () {})
              .then(function () {});
      },
      fetch_restaurant_types: function (t) {
          Vue.axios
              .get("".concat(BASE_URL, "gettypes"))
              .then(function (a) {
                  var e = a.data;
                  localStorage.setItem("restaurant_types", JSON.stringify(e)), t.commit("FETCH_RESTAURANT_TYPES", JSON.stringify(e));
              })
              .catch(function () {})
              .then(function () {});
      },
      store_login: function (t, a) {
          localStorage.setItem("user_data", JSON.stringify(a.user)), localStorage.setItem("user_token", JSON.stringify(a.success.token));
          var e = { success: { token: JSON.stringify(a.success.token) }, user: JSON.stringify(a.user) };
          t.commit("SUCCESSFUL_LOGIN", e);
      },
      update_profile: function (context, user) {
            localStorage.setItem("user_data", JSON.stringify(user));
            let _user = JSON.stringify(user);
            context.commit("PROFILE_UPDATED", _user);
        },
      logout: function (t) {
          t.getters.logged_in &&
              (localStorage.removeItem("user_token"),
              localStorage.removeItem("user_data"),
              localStorage.removeItem("restaurant_types"),
              localStorage.removeItem("vendor_restaurants"),
              localStorage.removeItem("all_menu"),
              localStorage.removeItem("vendor_menu_categories"),
              localStorage.removeItem("all_order_items"),
              localStorage.removeItem("all_orders"),
              t.commit("LOGOUT_USER"));
      }
  },
  modules: {
  }
})
