<template>
    <div>
        <figure>
            <a href="https://chops.ng" target="_blank">
                <img src="/assets/img/logo_sticky.png" alt="" />
            </a>
        </figure>
        <form autocomplete="off">
            <div class="form-group">
                <input v-model="form_data.email" type="email" placeholder="Email" class="form-control" />
                <i class="icon_mail_alt"></i>
            </div>
            <div class="form-group">
                <input v-model="form_data.password" type="password" id="password1" placeholder="Password" class="form-control" />
                <i class="icon_lock_alt"></i>
            </div>
            <div class="clearfix add_bottom_15">
                <div class="checkboxes float-left">
                    <label class="container_check">
                        Remember me 
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="float-right">
                    <router-link to="/forgotpassword" class="" id="forgot">
                        Forgot Password?
                    </router-link>
                </div>
            </div>
            <button @click.prevent="login_request()" class="btn_1 gradient full-width">
                <span>
                    {{ saving_text }}
                    <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : ''"></i>
                </span>
            </button>
            <div class="text-center mt-2">
                <small>
                    Don't have an acccount? <strong> <router-link :to="{ name: 'Register' }" class="">Sign Up</router-link></strong>
                </small>
            </div>
        </form>
        <i class="fad fa-spinner" style="opacity: 0;"></i>
    </div>
</template>

<script>
    import { BASE_URL } from "@/main";
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                form_data: {
                    email: "",
                    password: "",
                },
                saving_text: "Login Now!",
                saving_load: false,
            };
        },
        computed: {
            ...mapGetters([
                'auth_restaurant_types'
            ])
        },
        methods: {
            ...mapActions([
                'store_login',
                'fetch_restaurant_types'
            ]),
            login_request() {
                let _this = this;
                if (!_this.saving_load) {
                    _this.saving_text = "";
                    _this.saving_load = true;
                    _this.axios.post(`${BASE_URL}vendorlogin`, {
                            email: _this.form_data.email, 
                            password: _this.form_data.password
                        })
                        .then((response) => {
                            _this.store_login(response.data);
                        })
                        .catch(() => {
                            _this.$swal.fire({
                                title: "Error", 
                                text: "Please enter correct login details.", 
                                icon: "error"
                            });
                        })
                        .then(() => {
                            _this.saving_text = "Login Now!";
                            _this.saving_load = false;
                        });
                }
            }
        },
        mounted() {
            document.title = 'Login - Chops';
        }
    };
</script>

<style></style>
