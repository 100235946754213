<template>
  <div id="app" :class="logged_in ? 'fixed-nav sticky-footer' : null">
    <link v-if="!logged_in" href="/assets/css/style.css" rel="stylesheet" />
    <template v-if="logged_in">
      <Navbar />
      <div class="content-wrapper">
        <router-view />
      </div>
      <Footer />
      <Addons />
      <LogoutModal />
    </template>
    <div v-else id="register_bg">
      <div id="register">
        <aside>
          <router-view />
          <div class="copy">&copy; {{ current_year }} Chops</div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Navbar from '../src/components/Navbar';
import Footer from '../src/components/Footer';
import Addons from '../src/components/Addons';
import LogoutModal from '../src/components/LogoutModal';
export default {
  watch: {
    logged_in(new_value) {
      let _this = this;
      if (new_value) {
        _this.fetch_vendor_restaurants();
        _this.fetch_all_order_items();
        _this.fetch_all_menu();
        _this.fetch_all_orders();
      }
    }
  },
  computed: {
    ...mapGetters([
      'logged_in',
      'current_year'
    ]),
    current_year: function () {
        return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions([
      'fetch_vendor_restaurants',
      'fetch_all_order_items',
      'fetch_all_menu',
      'fetch_all_orders',
      'fetch_restaurant_types',
      'fetch_vendor_menu_categories',
      'logout'
    ])
  },
  components: {
    Navbar,
    Footer,
    Addons,
    LogoutModal
  },
  mounted() {
    this.fetch_restaurant_types();
    this.fetch_vendor_restaurants();
    this.fetch_all_menu();
  },
  onIdle() {
    let _this = this;
    if (_this.logged_in) {
      _this.logout();
    }
  }
}
</script>


<style>

</style>
