<template>
  <div class="container-fluid">
    <!-- Breadcrumbs-->
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <router-link :to="{ name: 'Home' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">My Dashboard</li>
    </ol>
    <!-- Icon Cards-->
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-3">
            <div class="card dashboard text-white bg-primary o-hidden h-100">
                <div class="card-body">
                    <div class="card-body-icon">
                        <i class="fa fa-fw fa-shopping-cart"></i>
                    </div>
                    <div class="mr-5">
                        <!-- <div v-if="1 > 0" class="d-flex justify-content-center">
                            <div class="fad fa-spinner fa-spin" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> -->
                        <h5 v-if="loading_orders" class="fad fa-spinner fa-spin">
                        </h5>
                        <h5 v-else>
                            {{ (auth_all_orders.length >= 1 ? auth_all_orders.length : "No") + " Order" + (auth_all_orders.length == 1  ? "" : "s") }}
                        </h5>
                    </div>
                </div>
                <router-link class="card-footer text-white clearfix small z-1" :to="{ name: 'Orders' }">
                    <span class="float-left">View Orders</span>
                    <span class="float-right">
                        <i class="fa fa-angle-right"></i>
                    </span>
                </router-link>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-3">
            <div class="card dashboard text-white bg-success o-hidden h-100">
                <div class="card-body">
                    <div class="card-body-icon">
                        <i class="fa fa-fw fa-store-alt"></i>
                    </div>
                    <div class="mr-5">
                        <h5 v-if="loading_restaurants" class="fad fa-spinner fa-spin">
                        </h5>
                        <h5 v-else>
                            {{ (auth_vendor_restaurants.length >= 1 ? auth_vendor_restaurants.length : "No") + " Restaurant" + (auth_vendor_restaurants.length == 1  ? "" : "s") }}
                        </h5>
                    </div>
                </div>
                <router-link class="card-footer text-white clearfix small z-1" :to="{ name: 'Restaurants' }">
                    <span class="float-left">View Restaurants</span>
                    <span class="float-right">
                        <i class="fa fa-angle-right"></i>
                    </span>
                </router-link>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-3">
            <div class="card dashboard text-white bg-secondary o-hidden h-100">
                <div class="card-body">
                    <div class="card-body-icon">
                        <i class="fa fa-fw fa-utensils"></i>
                    </div>
                    <div class="mr-5">
                        <h5 v-if="loading_menus" class="fad fa-spinner fa-spin">
                        </h5>
                        <h5 v-else>
                            {{ (auth_vendor_menu.length >= 1 ? auth_vendor_menu.length : "No") + " Menu" + (auth_vendor_menu.length == 1  ? "" : "s") }}
                        </h5>
                    </div>
                </div>
                <router-link class="card-footer text-white clearfix small z-1" :to="{ name: 'Menus' }">
                    <span class="float-left">View Menus</span>
                    <span class="float-right">
                        <i class="fa fa-angle-right"></i>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
    <!-- /cards -->
    <h2></h2>
    <div class="box_general padding_bottom">
        <div class="header_box version_2">
            <h2><i class="fa fa-bar-chart"></i>Order Statistics</h2>
        </div>
        <Chart 
        :chartdata="chart_data" 
        :options="chart_options"/>
        <!-- <canvas id="myBarChart" width="100%" height="30" style="margin:45px 0 15px 0;"></canvas> -->
    </div>
  </div>
  <!-- /.container-fluid-->
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// @ is an alias to /src
import Chart from '../components/Chart'
export default {
//   watch: {
//     auth_all_orders() {
//         this.loading_orders = false;
//     }
//   },
  methods: {
    ...mapActions([
        'fetch_restaurant_types', 
        'fetch_vendor_restaurants', 
        'fetch_all_menu', 
        'fetch_all_order_items', 
        'fetch_all_orders'
    ])
  }, 
  computed: {
    ...mapGetters([
        'auth_user_data', 
        'auth_restaurant_types', 
        'auth_vendor_restaurants', 
        'auth_vendor_menu', 
        'auth_all_orders',
    ]),
    ...mapState([
        'loading_orders',
        'loading_restaurants',
        'loading_menus'
    ]),
    chart_data() {
        let _this = this;
        return {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
                { 
                    label: 'Orders (' + new Date().getFullYear() + ')', 
                    backgroundColor: '#007bff', 
                    data: _this.monthly_orders
                }
            ]
        }
    },
    chart_options() {
        return { responsive: true, maintainAspectRatio: false };
    },
    monthly_orders() {
        let orders = [], _this = this, current_year = new Date().getFullYear();
        let all_months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        all_months.forEach(month => {
            let monthly_orders = _this.auth_all_orders.filter(order => {
                return order.month_name == month && order.year == current_year;
            });
            orders.splice(orders.length, 0, monthly_orders.length);
        });
        return orders;
    }
  },
  components: {
      Chart
  },
  mounted() {
        document.title = 'Home - Chops';
        this.auth_restaurant_types || this.fetch_restaurant_types();
        this.fetch_vendor_restaurants();
        this.fetch_all_order_items();
        this.fetch_all_orders();
        this.fetch_all_menu();
  }
}
</script>
<style>
canvas {
    margin: auto !important;
}
</style>