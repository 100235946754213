<template>
    <div>
        <figure>
            <a href="https://chops.ng" target="_blank">
                <img src="/assets/img/logo_sticky.png" alt="" />
            </a>
        </figure>
        <form autocomplete="off">
            <div class="form-group">
                <input v-model="form_data.name" type="text" placeholder="Name" class="form-control" />
                <i class="icon_pencil-edit"></i>
            </div>
            <div class="form-group">
                <input v-model="form_data.email" type="email" placeholder="Email" class="form-control" />
                <i class="icon_mail_alt"></i>
            </div>
            <div class="form-group">
                <input v-model="form_data.phone" type="text" placeholder="Phone Number" class="form-control" />
                <i class="icon_phone"></i>
            </div>
            <div class="form-group">
                <input v-model="form_data.password" type="password" id="password1" placeholder="Password" class="form-control" />
                <i class="icon_lock_alt"></i>
            </div>
            <div class="form-group">
                <input v-model="form_data.confirm_password" type="password" id="password2" placeholder="Confirm Password" class="form-control" />
                <i class="icon_lock_alt"></i>
            </div>
            <div id="pass-info" class="clearfix"></div>
            <button @click.prevent="register_request()" class="btn_1 gradient full-width">
                <span>
                    {{ saving_text }}
                    <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : ''"></i>
                </span>
            </button>
            <div class="text-center mt-2">
                <small>
                    Already have an acccount? <strong> <router-link :to="{ name: 'Login' }" class="">Sign In</router-link></strong>
                </small>
            </div>
        </form>
    </div>
</template>

<script>
    import { BASE_URL } from "@/main";
    export default {
        data() {
            return {
                form_data: {
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                    confirm_password: "",
                },
                saving_text: "Register Now!",
                saving_load: false,
            };
        },
        methods: {
            register_request() {
                let _this = this;
                if (!_this.saving_load) {
                    if (_this.form_data.password != _this.form_data.confirm_password) {
                        _this.$swal.fire({
                          title: "Uh-oh", 
                          text: "Password and password confirmation don't match!", 
                          icon: "info"
                        });
                        return;
                    }
                    if (_this.form_data.password.length < 8) {
                        _this.$swal.fire({
                          title: "Too short", 
                          text: "Please ensure your password is at least 8 characters long.", 
                          icon: "info"
                        });
                        return;
                    }
                    _this.saving_text = "";
                    _this.saving_load = true;
                    _this.axios
                        .post(`${BASE_URL}vendorregister`, {
                            name: _this.form_data.name,
                            email: _this.form_data.email,
                            phone: _this.form_data.phone,
                            password: _this.form_data.password,
                            confirm_password: _this.form_data.confirm_password,
                        })
                        .then(function () {
                            _this.$swal.fire({
                                title: "Great!", 
                                text: "Account creation successful. You'll be redirected to the login page.", 
                                icon: "success"
                                }).then(() => {
                                _this.$router.push({ name: "Login" });
                            });
                        })
                        .catch(function () {
                            _this.$swal.fire({
                              title: "Error", 
                              text: "Please enter correct registration details.",
                              icon: "error"
                            });
                        })
                        .then(function () {
                            _this.saving_text = "Register Now!";
                            _this.saving_load = false;
                        });
                }
            },
        },
        mounted() {
            document.title = 'Register - Chops';
        }
    };
</script>

<style></style>
