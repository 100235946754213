<template>
    <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Menus</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-utensils"></i> 
                Your Menus 
                <router-link :to="{ name: 'AddMenu' }" tag="button" class="btn_1 gradient full-width" style="float: right;">
                    <span> Add Menu </span>
                </router-link>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Restaurant Name</th>
                                <th>Category</th>
                                <th>Food Name</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Menu Type</th>
                                <th>Date Added</th>
                                <th style="width: 75px;">Action</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Restaurant Name</th>
                                <th>Category</th>
                                <th>Food Name</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Menu Type</th>
                                <th>Date Added</th>
                                <th style="width: 75px;">Action</th>
                            </tr>
                        </tfoot>
                        <tbody :key="component_key">
                            <tr v-for="menu in auth_vendor_menu" :key="menu.id">
                                <td>{{ menu.restaurant_name }}</td>
                                <td>{{ menu.category_name }}</td>
                                <td>{{ menu.food_name }}</td>
                                <td>&#8358;{{ parseInt(menu.price) | number_with_commas }}</td>
                                <td>{{ menu.quantity }}</td>
                                <td>{{ menu.menu_type }}</td>
                                <td>{{ new Date(menu.created_at).toDateString() }}</td>
                                <td>
                                    <a @click="launch_editing(menu)" data-toggle="modal" data-target="#editMenu" style="color: rgb(13, 146, 25); cursor: pointer;">
                                        <strong>Edit</strong>
                                    </a> | 
                                    <a @click="delete_menu(menu)" style="cursor: pointer;">
                                        <strong class="text-danger">Delete</strong>
                                    </a>
                                </td>
                                <!-- USE MODAL BELOW FOR MENU IMAGE LATER ON -->
                                <!-- <div class="modal fade" :id="'restaurantLogo' + restaurant.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <img :src="restaurant.logo_url ? (assets_url + '' + restaurant.logo_url) : '/assets/img/no-image-available.jpeg'" alt="" style="width: 100%;">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal fade" id="editMenu" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                                Edit Menu
                            </h5>
                            <button id="logout_button" type="button" data-dismiss="modal" aria-label="Close" class="close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Restaurant</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.restaurant_id">
                                                <option 
                                                selected="selected" 
                                                disabled="disabled" 
                                                value="Select restaurant">
                                                    Select restaurant
                                                </option>
                                                <option 
                                                v-for="restaurant in auth_vendor_restaurants" 
                                                :key="restaurant.id" 
                                                :value="restaurant.id">
                                                {{ restaurant.restaurant_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.category_id">
                                                <option 
                                                selected="selected" 
                                                disabled="disabled" 
                                                value="Select category">
                                                    Select category
                                                </option>
                                                <option 
                                                v-for="category in all_menu_categories" 
                                                :key="category.id" 
                                                :value="category.id">
                                                {{ category.category_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Quantity</label>
                                        <input 
                                        placeholder="0"
                                        v-model="edit_form_data.quantity" 
                                        type="number" 
                                        class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Menu type</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.menu_type">
                                                <option 
                                                selected="selected" 
                                                disabled="disabled" 
                                                value="Select menu type">
                                                    Select menu type
                                                </option>
                                                <option 
                                                value="Breakfast">
                                                Breakfast
                                                </option>
                                                <option 
                                                value="All day">
                                                All day
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description (optional)</label>
                                        <textarea
                                        class="form-control"
                                        style="height: 100px;" 
                                        v-model="edit_form_data.description"
                                        placeholder="Enter menu description">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Item name</label>
                                        <input 
                                        placeholder="Enter item name"
                                        v-model="edit_form_data.food_name" 
                                        type="text" 
                                        class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Price (per item)</label>
                                        <input 
                                        placeholder="Enter price"
                                        v-model="edit_form_data.price" 
                                        type="text" 
                                        class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button id="close_edit_menu" type="button" data-dismiss="modal" class="btn btn-secondary">
                                Cancel
                            </button>
                            <button @click.prevent="edit_menu()" class="text-white btn_1 medium"> 
                                {{ saving_text }} 
                                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 60px;'"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="auth_vendor_menu.length >= 1" class="card-footer small text-muted">Updated {{ last_update }}</div>
        </div>
        <!-- /tables-->
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL, ASSETS_URL } from '@/main';
export default {
    data() {
        return {
            saving_text: "Update",
            saving_load: false,
            edit_form_data: { 
                id: "", 
                restaurant_id: "Select restaurant", 
                category_id: "Select category", 
                quantity: "", 
                menu_type: "Select menu type", 
                description: "", 
                food_name: "", 
                price: "" 
            },
            component_key: 1
        }
    },
    methods: {
        ...mapActions([
            'fetch_restaurant_types',
            'fetch_vendor_restaurants',
            'fetch_all_menu',
            'fetch_vendor_menu_categories'
        ]),
        launch_editing(menu) {
            let _this = this;
            _this.edit_form_data.id = menu.id;
            _this.edit_form_data.restaurant_id = parseInt(menu.restaurant_id);
            _this.edit_form_data.category_id = parseInt(menu.category_id);
            _this.edit_form_data.quantity = menu.quantity;
            _this.edit_form_data.menu_type = menu.menu_type;
            _this.edit_form_data.description = menu.description;
            _this.edit_form_data.food_name = menu.food_name;
            _this.edit_form_data.price = menu.price;
        },
        edit_menu() {
            let _this = this;
            if(!_this.saving_load) {
                _this.saving_text = "";
                _this.saving_load = true;
                _this.axios
                    .post(`${BASE_URL}updatemenu`, {
                        id: _this.edit_form_data.id,
                        restaurant_id: "Select restaurant" != _this.edit_form_data.restaurant_id ? _this.edit_form_data.restaurant_id : "",
                        category_id: "Select category" != _this.edit_form_data.category_id ? _this.edit_form_data.category_id : "",
                        quantity: _this.edit_form_data.quantity,
                        menu_type: "Select menu type" != _this.edit_form_data.menu_type ? _this.edit_form_data.menu_type : "",
                        description: _this.edit_form_data.description,
                        food_name: _this.edit_form_data.food_name,
                        price: _this.edit_form_data.price,
                    })
                    .then(function () {
                        _this.fetch_all_menu();
                        _this.edit_form_data.restaurant_id = "Select restaurant";
                        _this.edit_form_data.category_id = "Select category";
                        _this.edit_form_data.quantity = "";
                        _this.edit_form_data.menu_type = "Select menu type";
                        _this.edit_form_data.description = "";
                        _this.edit_form_data.food_name = "";
                        _this.edit_form_data.price = "";
                        _this.$swal.fire("Great!", "The menu has been updated successfully.", "success").then(() => {
                            document.querySelector("#close_edit_menu").click();
                        });
                        document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;
                    })
                    .catch(function () {
                        _this.$swal.fire(
                            "Error", 
                            "Please enter correct editing details.", 
                            "error"
                        );
                    })
                    .then(function () {
                        _this.saving_text = "Update";
                        _this.saving_load = false;
                    });
            }
        },
        
        delete_menu(menu) {
            let _this = this;

            _this.$swal
                .fire({
                title: 'Are you sure you want to delete this menu - "' + menu.food_name + '"?',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`
                })
                .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                _this.axios
                    .post(`${BASE_URL}deletemenu`, {
                        id: menu.id
                    })
                    .then(function() {
                        _this.fetch_all_menu().then(() => {
                            setTimeout(() => {
                                window.$('#dataTable').DataTable()
                                .search( '' )
                                .columns().search( '' )
                                .destroy();

                                _this.$swal.fire({
                                    title: "Great!", 
                                    text: "The menu has been deleted successfully.", 
                                    icon: "success"
                                });
                                document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;

                                setTimeout(() => {
                                    _this.component_key += 1;
                                    window.$('#dataTable').DataTable()
                                    .search( '' )
                                    .columns().search( '' )
                                    .draw();
                                }, 1000);
                            }, 2000);
                        })

                    })
                    .catch(function() {
                        _this.$swal.fire({
                            title: "Error", 
                            text: "The menu couldn't be deleted. Check connection and retry.", 
                            icon: "success"
                        });
                    })
                    .then(function() {
                        //
                    });
                }
                });
        },
        timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " years"
            : Math.floor(interval) + " year";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " months"
            : Math.floor(interval) + " month";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " days"
            : Math.floor(interval) + " day";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " hours"
            : Math.floor(interval) + " hour";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " minutes"
            : Math.floor(interval) + " minute";
        }
        return Math.floor(seconds) != 1
            ? Math.floor(seconds) + " seconds"
            : Math.floor(seconds) + " second";
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
            'auth_restaurant_types',
            'auth_vendor_restaurants',
            'auth_vendor_menu',
            'all_menu_categories'
        ]),
        last_update() {
            let all_updates = [], _this = this, max = null, time_since = null, string = null;
            if (_this.auth_vendor_menu) {
                _this.auth_vendor_menu.forEach(menu => {
                    all_updates.splice(all_updates.length, 0, new Date(menu.updated_at).getTime());
                });
                max = Math.max(...all_updates);
                time_since = _this.timeSince(max);
                string = (time_since.indexOf('second') != -1 || time_since.indexOf('minute') != -1) ? (time_since + ' ago') : (time_since + ' ago - ' + new Date(max).toDateString() + ', ' + new Date(max).toLocaleTimeString());
                return string;
            } else {
                return null
            }
        },
        assets_url() {
            return ASSETS_URL;
        }
    },
    mounted() {
        document.title = 'Menus - Chops';
        this.fetch_all_menu();
        this.fetch_vendor_menu_categories();
        window.$('#dataTable').DataTable()
        .search( '' )
        .columns().search( '' )
        .draw();
    },
}
</script>

<style scoped>
.form-group {
    text-align: left;
}
</style>