<template>
    <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Restaurants</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-store-alt"></i> 
                Your Restaurants 
                <router-link :to="{ name: 'AddRestaurant' }" tag="button" class="btn_1 gradient full-width" style="float: right;">
                    <span> Add Restaurant </span>
                </router-link>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Restaurant Logo</th>
                                <th>Restaurant Banner</th>
                                <th>Restaurant Name</th>
                                <th>Location</th>
                                <th>Phone</th>
                                <th>Date Registered</th>
                                <th style="width: 75px;">Action</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Restaurant Logo</th>
                                <th>Restaurant Banner</th>
                                <th>Restaurant Name</th>
                                <th>Location</th>
                                <th>Phone</th>
                                <th>Date Registered</th>
                                <th style="width: 75px;">Action</th>
                            </tr>
                        </tfoot>
                        <!-- <div v-if="loading_restaurants">
                            <div class="text-center">
                                <div class="fad fa-spinner fa-spin" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div> -->
                        <tbody :key="component_key">
                            <tr v-for="restaurant in auth_vendor_restaurants" :key="restaurant.id">
                                <td>
                                    <a data-toggle="modal" :data-target="'#restaurantLogo' + restaurant.id" style="color: rgb(13, 146, 25); cursor: pointer;">
                                        <strong>View</strong>
                                    </a> | 
                                    <a @click="launch_image_edit(restaurant, 'logo')" data-toggle="modal" data-target="#editLogoImage" style="cursor: pointer;">
                                        <strong class="text-info">Change</strong>
                                    </a>
                                </td>
                                <td>
                                    <a data-toggle="modal" :data-target="'#restaurantBanner' + restaurant.id" style="color: rgb(13, 146, 25); cursor: pointer;">
                                        <strong>View</strong>
                                    </a> | 
                                    <a @click="launch_image_edit(restaurant, 'banner')" data-toggle="modal" data-target="#editBannerImage" style="cursor: pointer;">
                                        <strong class="text-info">Change</strong>
                                    </a>
                                </td>
                                <td>{{ restaurant.restaurant_name }}</td>
                                <td>{{ restaurant.address }}</td>
                                <td>{{ restaurant.phone }}</td>
                                <td>{{ new Date(restaurant.created_at).toDateString() }}</td>
                                <td>
                                    <a @click="launch_editing(restaurant)" data-toggle="modal" data-target="#editRestaurant" style="color: rgb(13, 146, 25); cursor: pointer;">
                                        <strong>Edit</strong>
                                    </a> | 
                                    <a @click="delete_restaurant(restaurant)" style="cursor: pointer;">
                                        <strong class="text-danger">Delete</strong>
                                    </a>
                                </td>
                                <div class="modal fade" :id="'restaurantLogo' + restaurant.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <img :src="restaurant.logo_url ? (assets_url + '' + restaurant.logo_url) : '/assets/img/no-image-available.jpeg'" alt="" style="width: 100%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" :id="'restaurantBanner' + restaurant.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <img :src="restaurant.image_url ? (assets_url + '' + restaurant.image_url) : '/assets/img/no-image-available.jpeg'" alt="" style="width: 100%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal fade" :id="image_type == 'banner' ? 'editBannerImage' : 'editLogoImage'" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 v-if="image_type == 'banner'" id="exampleModalLabel" class="modal-title">
                                Upload Restaurant Image
                            </h5>
                            <h5 v-else id="exampleModalLabel" class="modal-title">
                                Upload Restaurant Logo
                            </h5>
                            <button id="logout_button" type="button" data-dismiss="modal" aria-label="Close" class="close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-left">
                                        <label>Click below to select a new restaurant logo</label>
                                        <form>
                                            <input type="file" id="newLogo">
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button id="closeImageUpload" type="button" data-dismiss="modal" class="btn btn-secondary">
                                Cancel
                            </button>
                            <button @click.prevent="submit_image()" class="text-white btn_1 medium"> 
                                {{ saving_text2 }} 
                                <i class="fad fa-spinner fa-spin" :style="!saving_load2 ? 'display: none;' : 'width: 60px;'"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="editRestaurant" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="exampleModalLabel" class="modal-title">
                                Edit Restaurant
                            </h5>
                            <button id="logout_button" type="button" data-dismiss="modal" aria-label="Close" class="close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Restaurant name</label>
                                        <input v-model="edit_form_data.restaurant_name" type="text" placeholder="Enter restaurant name" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>City or town</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.city">
                                                <option selected="selected" disabled="disabled" value="Select a city or town">
                                                    Select a city or town
                                                </option>
                                                <option v-for="city in chops_cities" :key="city" :value="city">
                                                    {{ city }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <input v-model="edit_form_data.phone" type="text" placeholder="Enter phone number" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Address (primary location)</label>
                                        <textarea v-model="edit_form_data.address" type="text" placeholder="ex. 250, Fifth Avenue..." class="form-control">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group no_label">
                                        <label v-for="type in auth_restaurant_types" :key="type.id" class="container_check">
                                            {{ type.type_name }}
                                            <input
                                            :value="type.id" 
                                            type="checkbox"
                                            :checked="edit_form_data.restaurant_type.indexOf(type.id) != -1" 
                                            name="service"
                                            @change="handle_checkbox(type.id)" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Opening time</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.opening_time">
                                                <option value="00">12 AM</option>
                                                <option value="1">1 AM</option>
                                                <option value="2">2 AM</option>
                                                <option value="3">3 AM</option>
                                                <option value="4">4 AM</option>
                                                <option value="5">5 AM</option>
                                                <option value="6">6 AM</option>
                                                <option value="7">7 AM</option>
                                                <option value="8">8 AM</option>
                                                <option value="9">9 AM</option>
                                                <option value="10">10 AM</option>
                                                <option value="11">11 AM</option>
                                                <option value="12">12 PM</option>
                                                <option value="13">1 PM</option>
                                                <option value="14">2 PM</option>
                                                <option value="15">3 PM</option>
                                                <option value="16">4 PM</option>
                                                <option value="17">5 PM</option>
                                                <option value="18">6 PM</option>
                                                <option value="19">7 PM</option>
                                                <option value="20">8 PM</option>
                                                <option value="21">9 PM</option>
                                                <option value="22">10 PM</option>
                                                <option value="23">11 PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Closing time</label>
                                        <div class="styled-select">
                                            <select v-model="edit_form_data.closing_time">
                                                <option value="00">12 AM</option>
                                                <option value="1">1 AM</option>
                                                <option value="2">2 AM</option>
                                                <option value="3">3 AM</option>
                                                <option value="4">4 AM</option>
                                                <option value="5">5 AM</option>
                                                <option value="6">6 AM</option>
                                                <option value="7">7 AM</option>
                                                <option value="8">8 AM</option>
                                                <option value="9">9 AM</option>
                                                <option value="10">10 AM</option>
                                                <option value="11">11 AM</option>
                                                <option value="12">12 PM</option>
                                                <option value="13">1 PM</option>
                                                <option value="14">2 PM</option>
                                                <option value="15">3 PM</option>
                                                <option value="16">4 PM</option>
                                                <option value="17">5 PM</option>
                                                <option value="18">6 PM</option>
                                                <option value="19">7 PM</option>
                                                <option value="20">8 PM</option>
                                                <option value="21">9 PM</option>
                                                <option value="22">10 PM</option>
                                                <option value="23">11 PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button id="close_edit_restaurant" type="button" data-dismiss="modal" class="btn btn-secondary">
                                Cancel
                            </button>
                            <button @click.prevent="edit_restaurant()" class="text-white btn_1 medium"> 
                                {{ saving_text }} 
                                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 60px;'"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="auth_vendor_restaurants.length >= 1" class="card-footer small text-muted">Updated {{ last_update }}</div>
        </div>
        <!-- /tables-->
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters} from 'vuex';
import { BASE_URL, ASSETS_URL } from '@/main';
export default {
    data() {
        return {
            saving_text: "Update",
            saving_load: false,
            saving_text2: "Upload",
            saving_load2: false,
            edit_form_data: { 
                restaurant_id: "", 
                restaurant_name: "", 
                city: "Select a city or town", 
                address: "", 
                phone: "", 
                restaurant_type: [], 
                opening_time: "00", 
                closing_time: "00" 
            },
            image_type: '',
            component_key: 1
        }
    },
    methods: {
        ...mapActions([
            'fetch_restaurant_types',
            'fetch_vendor_restaurants'
        ]),
        handle_checkbox(id) {
            let _this = this;
            if (_this.edit_form_data.restaurant_type.indexOf(id) != -1) {
                _this.edit_form_data.restaurant_type.splice(_this.edit_form_data.restaurant_type.indexOf(id), 1)
            } else {
                _this.edit_form_data.restaurant_type.splice((_this.edit_form_data.restaurant_type.length - 1), 0, id)
            }
        },
        launch_image_edit(restaurant, type) {
            this.edit_form_data.restaurant_id = restaurant.id;
            this.image_type = type;
        },
        submit_image: function () {
            let _this = this;
            if (_this.image_type == 'banner') {
                if (!document.querySelector("#newLogo").files[0]) {
                    _this.$swal.fire("Err...", "You haven't chosen a new image.", "warning");
                    return;
                }
                if (!_this.saving_load2) {
                    (_this.saving_text2 = ""), (_this.saving_load2 = true);
                    let form = new FormData();
                    form.append("restaurant_id", _this.edit_form_data.restaurant_id),
                        form.append("file", document.querySelector("#newLogo").files[0]),
                        _this.axios.post(`${BASE_URL}postrestimage`, form, { 
                            headers: { "Content-Type": "multipart/form-data" } 
                        })
                        .then(function () {
                            _this.fetch_vendor_restaurants().then(() => {
                                document.querySelector("#newLogo").value = null;
                                _this.$swal.fire("Great!", "The restaurant image has been updated successfully.", "success").then(function () {
                                    document.querySelector("#closeImageUpload").click();
                                });
                                document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;
                            })
                        })
                        .catch(function () {
                            _this.$swal.fire("Error", "The restaurant image could not be updated.", "error");
                        })
                        .then(function () {
                            (_this.saving_load2 = false), (_this.saving_text2 = "Upload");
                        });
                }
            }
            if (_this.image_type == 'logo') {
                if (!document.querySelector("#newLogo").files[0]) {
                    _this.$swal.fire("Err...", "You haven't chosen a new image.", "warning");
                    return;
                }
                if (!_this.saving_load2) {
                    (_this.saving_text2 = ""), (_this.saving_load2 = true);
                    let form = new FormData();
                    form.append("restaurant_id", _this.edit_form_data.restaurant_id),
                        form.append("file", document.querySelector("#newLogo").files[0]),
                        _this.axios.post(`${BASE_URL}postrestlogo`, form, { 
                            headers: { "Content-Type": "multipart/form-data" } 
                        })
                        .then(function () {
                            _this.fetch_vendor_restaurants().then(() => {
                                document.querySelector("#newLogo").value = null;
                                _this.$swal.fire("Great!", "The restaurant logo has been updated successfully.", "success").then(function () {
                                    document.querySelector("#closeImageUpload").click();
                                });
                                document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;
                            });
                        })
                        .catch(function () {
                            _this.$swal.fire("Error", "The restaurant logo could not be updated.", "error");
                        })
                        .then(function () {
                            (_this.saving_load2 = false), (_this.saving_text2 = "Upload");
                        });
                }
            }
        },
        launch_editing: function (restaurant) {
            let _this = this;
            _this.edit_form_data.restaurant_id = restaurant.id;
            _this.edit_form_data.restaurant_name = restaurant.restaurant_name;
            _this.edit_form_data.address = restaurant.address;
            let types = restaurant.type_id.split(",");
            (types = types.map(function (id) {
                return parseInt(id);
            })),
                (_this.edit_form_data.restaurant_type = types),
                (_this.edit_form_data.phone = restaurant.phone),
                (_this.edit_form_data.city = restaurant.state),
                (_this.edit_form_data.opening_time = restaurant.opening_time),
                (_this.edit_form_data.closing_time = restaurant.closing_time);
        },
        edit_restaurant: function () {
            let _this = this;
            _this.saving_load ||
                ((_this.saving_text = ""),
                (_this.saving_load = true),
                _this.axios
                    .post(`${BASE_URL}updaterestaurant`, {
                        restaurant_id: _this.edit_form_data.restaurant_id,
                        restaurant_name: _this.edit_form_data.restaurant_name,
                        address: _this.edit_form_data.address,
                        type_id: _this.edit_form_data.restaurant_type,
                        phone: _this.edit_form_data.phone,
                        city: "Select a city or town" != _this.edit_form_data.city ? _this.edit_form_data.city : "",
                        user_id: _this.auth_user_data.id,
                        opening_time: _this.edit_form_data.opening_time,
                        closing_time: _this.edit_form_data.closing_time,
                    })
                    .then(function () {
                        _this.fetch_vendor_restaurants().then(() => {
                            document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;
                            _this.$swal.fire("Great!", "The restaurant has been updated successfully.", "success").then(function () {
                                document.querySelector("#close_edit_restaurant").click();
                            }).then(() => {
                                _this.edit_form_data.restaurant_name = "";
                                _this.edit_form_data.address = "";
                                _this.edit_form_data.restaurant_type = [];
                                _this.edit_form_data.phone = "";
                                _this.edit_form_data.city = "Select a city or town";
                                _this.edit_form_data.opening_time = "00";
                                _this.edit_form_data.closing_time = "00";
                            });
                        });
                    })
                    .catch(function () {
                        _this.$swal.fire("Error", "Please enter correct editing details.", "error");
                    })
                    .then(function () {
                        (_this.saving_text = "Update"), (_this.saving_load = false);
                    }));
        },
        delete_restaurant(restaurant) {
            let _this = this;

            _this.$swal
                .fire({
                title: 'Are you sure you want to delete this restaurant - "' + restaurant.restaurant_name + '"?',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`
                })
                .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                _this.axios
                    .post(`${BASE_URL}deleterestaurant`, {
                        id: restaurant.id
                    })
                    .then(function() {
                        _this.fetch_vendor_restaurants().then(() => {
                            window.$('#dataTable').DataTable()
                            .search( '' )
                            .columns().search( '' )
                            .destroy();

                            _this.$swal.fire({
                                title: "Great!", 
                                text: "The restaurant has been deleted successfully", 
                                icon: "success"
                            });
                            document.querySelectorAll('[aria-controls="dataTable"]')[1].value = null;

                            setTimeout(() => {
                                _this.component_key += 1;
                                window.$('#dataTable').DataTable()
                                .search( '' )
                                .columns().search( '' )
                                .draw();
                            }, 1000);
                        });
                    })
                    .catch(function() {
                        _this.$swal.fire({
                            title: "Error", 
                            text: "The restaurant couldn't be deleted. Check connection and retry.", 
                            icon: "success"
                        });
                    })
                    .then(function() {
                        //
                    });
                }
                });
        },
        timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " years"
            : Math.floor(interval) + " year";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " months"
            : Math.floor(interval) + " month";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " days"
            : Math.floor(interval) + " day";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " hours"
            : Math.floor(interval) + " hour";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) != 1
            ? Math.floor(interval) + " minutes"
            : Math.floor(interval) + " minute";
        }
        return Math.floor(seconds) != 1
            ? Math.floor(seconds) + " seconds"
            : Math.floor(seconds) + " second";
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
            'auth_restaurant_types',
            'auth_vendor_restaurants',
            'chops_cities'
        ]),
        last_update() {
            let all_updates = [], _this = this, max = null, time_since = null, string = null;
            if (_this.auth_vendor_restaurants) {
                _this.auth_vendor_restaurants.forEach(restaurant => {
                    all_updates.splice(all_updates.length, 0, new Date(restaurant.updated_at).getTime());
                });
                max = Math.max(...all_updates);
                time_since = _this.timeSince(max);
                string = (time_since.indexOf('second') != -1 || time_since.indexOf('minute') != -1) ? (time_since + ' ago') : (time_since + ' ago - ' + new Date(max).toDateString() + ', ' + new Date(max).toLocaleTimeString());
                return string;
            } else {
                return null
            }
        },
        assets_url() {
            return ASSETS_URL;
        }
    },
    mounted() {
        document.title = 'Restaurants - Chops';
        if (!this.auth_restaurant_types) {
            this.fetch_restaurant_types();
        }
        this.fetch_vendor_restaurants();
        window.$('#dataTable').DataTable()
        .search( '' )
        .columns().search( '' )
        .draw();
    },
}
</script>

<style scoped>
.form-group {
    text-align: left;
}
</style>