<template>
    <div>
        <figure>
            <a href="https://chops.ng" target="_blank">
                <img src="/assets/img/logo_sticky.png" alt="" />
            </a>
        </figure>
        <form autocomplete="off">
            <div class="form-group">
                <input v-model="form_data.password" type="password" placeholder="New Password" class="form-control" />
                <i class="icon_mail_alt"></i>
            </div>
            <button @click.prevent="reset_password_request()" class="btn_1 gradient full-width">
                <span>
                    {{ saving_text }}
                    <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : ''"></i>
                </span>
            </button>
            <div class="text-center mt-2">
                <small>
                    Go back to <strong> <router-link :to="{ name: 'Login' }" class="">Sign In</router-link></strong>
                </small>
            </div>
        </form>
    </div>
</template>

<script>
    import { BASE_URL } from "@/main";
    export default {
        data() {
            return {
                form_data: {
                    password: "",
                },
                saving_text: "Change Password!",
                saving_load: false,
            };
        },
        methods: {
            reset_password_request() {
                let _this = this;
                if (!_this.form_data.password) {
                    _this.$swal.fire({
                        title: "Uh-oh", 
                        text: "Please enter a new password.", 
                        icon: "error"
                    });
                    return;
                }
                if (!_this.form_data.password.length < 8) {
                    _this.$swal.fire({
                        title: "Too short", 
                        text: "Please ensure your password is at least 8 characters long.", 
                        icon: "info"
                    });
                    return;
                }
                if (!_this.saving_load) {
                    _this.saving_text = "";
                    _this.saving_load = true;
                    _this.axios.post(`${BASE_URL}saveresetpassword`, {
                            password: _this.form_data.password,
                            recovery_code: _this.$route.params.recovery_code
                        })
                        .then(() => {
                            _this.$swal.fire({
                                title: "Great!", 
                                text: "Password reset successful. Proceed to login.", 
                                icon: "success"
                            }).then(() => {
                                _this.$router.push({ name: 'Home' })
                            })
                        })
                        .catch(() => {
                            _this.$swal.fire({
                                title: "Error", 
                                text: "Password reset failed.", 
                                icon: "error"
                            });
                        })
                        .then(() => {
                            _this.saving_text = "Change Password!";
                            _this.saving_load = false;
                        });
                }
            }
        },
        mounted() {
            document.title = 'Password Recovery - Chops';
        }
    };
</script>

<style></style>
