<template>
  <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-xl-7 col-lg-9 pt-5">
                <figure><img src="/assets/img/404.svg" alt="" class="img-fluid" width="550" height="234"></figure>
                <p>We're sorry, but the page you were looking for doesn't exist.</p>
                <form @submit.prevent>
                    <div class="row">
                        <div class="col-lg-4 offset-lg-4">
                            <router-link 
                            :to="{ name: 'Home' }"
                            tag="button"
                            class="btn_1 gradient"
                            type="button">
                            Go home
                            </router-link>
                        </div>
                    </div>
                    <!-- /row -->
                    </form>
            </div>
        </div>
        <!-- /row -->
    </div>
    <!-- /container -->
</template>

<script>
export default {
    mounted() {
        document.title = '404 Error - Page not found';
    }
}
</script>

<style>

</style>