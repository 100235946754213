<template>
  <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Restaurants' }">Restaurants</router-link>
                </li>
                <li class="breadcrumb-item active">Add Restaurant</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="box_general padding_bottom">
            <div class="header_box version_2">
                <h2><i class="fas fa-store-alt"></i>Restaurant info</h2>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Restaurant name</label>
                        <input v-model="form_data.restaurant_name" type="text" placeholder="Enter restaurant name" class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>City or town</label>
                        <div class="styled-select">
                            <select v-model="form_data.city">
                                <option selected="selected" disabled="disabled" value="Select a city or town">
                                    Select a city or town
                                </option>
                                <option v-for="city in chops_cities" :key="city" :value="city">
                                    {{ city }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Phone</label>
                        <input v-model="form_data.phone" type="text" placeholder="Enter phone number" class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Address (primary location)</label>
                        <textarea v-model="form_data.address" type="text" placeholder="ex. 250, Fifth Avenue..." class="form-control">
                        </textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group no_label">
                        <label v-for="type in auth_restaurant_types" :key="type.id" class="container_check">
                            {{ type.type_name }}
                            <input
                            :value="type.id" 
                            type="checkbox"
                            :checked="form_data.restaurant_type.indexOf(type.id) != -1" 
                            name="service"
                            @change="handle_checkbox(type.id)" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Opening time</label>
                        <div class="styled-select">
                            <select v-model="form_data.opening_time">
                                <option value="00">12 AM</option>
                                <option value="1">1 AM</option>
                                <option value="2">2 AM</option>
                                <option value="3">3 AM</option>
                                <option value="4">4 AM</option>
                                <option value="5">5 AM</option>
                                <option value="6">6 AM</option>
                                <option value="7">7 AM</option>
                                <option value="8">8 AM</option>
                                <option value="9">9 AM</option>
                                <option value="10">10 AM</option>
                                <option value="11">11 AM</option>
                                <option value="12">12 PM</option>
                                <option value="13">1 PM</option>
                                <option value="14">2 PM</option>
                                <option value="15">3 PM</option>
                                <option value="16">4 PM</option>
                                <option value="17">5 PM</option>
                                <option value="18">6 PM</option>
                                <option value="19">7 PM</option>
                                <option value="20">8 PM</option>
                                <option value="21">9 PM</option>
                                <option value="22">10 PM</option>
                                <option value="23">11 PM</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Closing time</label>
                        <div class="styled-select">
                            <select v-model="form_data.closing_time">
                                <option value="00">12 AM</option>
                                <option value="1">1 AM</option>
                                <option value="2">2 AM</option>
                                <option value="3">3 AM</option>
                                <option value="4">4 AM</option>
                                <option value="5">5 AM</option>
                                <option value="6">6 AM</option>
                                <option value="7">7 AM</option>
                                <option value="8">8 AM</option>
                                <option value="9">9 AM</option>
                                <option value="10">10 AM</option>
                                <option value="11">11 AM</option>
                                <option value="12">12 PM</option>
                                <option value="13">1 PM</option>
                                <option value="14">2 PM</option>
                                <option value="15">3 PM</option>
                                <option value="16">4 PM</option>
                                <option value="17">5 PM</option>
                                <option value="18">6 PM</option>
                                <option value="19">7 PM</option>
                                <option value="20">8 PM</option>
                                <option value="21">9 PM</option>
                                <option value="22">10 PM</option>
                                <option value="23">11 PM</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="add-mb-5">
            <button @click.prevent="add_restaurant()" class="text-white btn_1 medium"> 
                {{ saving_text }} 
                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 40px;'"></i>
            </button>
            <button @click.prevent="add_restaurant2()" class="ml-1 btn_1 medium gray"> 
                {{ saving_text2 }} 
                <i class="fad fa-spinner fa-spin" :style="!saving_load2 ? 'display: none;' : 'padding: 0px 60px;'"></i>
            </button>
        </p>
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL } from '@/main';
export default {
    data() {
        return {
            form_data: { 
                restaurant_name: "", 
                city: "Select a city or town", 
                address: "", 
                phone: "", 
                restaurant_type: [], 
                opening_time: "00", 
                closing_time: "00" 
            },
            saving_text: "Save",
            saving_text2: "Save and add new",
            saving_load: false,
            saving_load2: false,
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
            'auth_restaurant_types',
            'chops_cities'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_restaurant_types',
            'fetch_vendor_restaurants'
        ]),
        handle_checkbox(id) {
            let _this = this;
            if (_this.form_data.restaurant_type.indexOf(id) != -1) {
                _this.form_data.restaurant_type.splice(_this.form_data.restaurant_type.indexOf(id), 1)
            } else {
                _this.form_data.restaurant_type.splice((_this.form_data.restaurant_type.length - 1), 0, id)
            }
        },
        add_restaurant() {
            let _this = this;
                if (!_this.saving_load) {
                    _this.saving_text = "";
                    _this.saving_load = true;
                    _this.axios
                        .post(`${BASE_URL}createrestaurant`, {
                            restaurant_name: _this.form_data.restaurant_name,
                            address: _this.form_data.address,
                            type_id: _this.form_data.restaurant_type,
                            phone: _this.form_data.phone,
                            city: "Select a city or town" != _this.form_data.city ? _this.form_data.city : "",
                            user_id: _this.auth_user_data.id,
                            opening_time: _this.form_data.opening_time,
                            closing_time: _this.form_data.closing_time,
                        })
                        .then(() => {
                            _this.fetch_vendor_restaurants().then(() => {
                                _this.form_data.restaurant_name = "";
                                _this.form_data.address = "";
                                _this.form_data.restaurant_type = [];
                                _this.form_data.phone = "";
                                _this.form_data.city = "Select a city or town";
                                _this.form_data.opening_time = "00";
                                _this.form_data.closing_time = "00";
                                _this.$swal.fire("Great!", "Restaurant creation successful. You'll be redirected to the restaurants page.", "success").then(() => {
                                    _this.$router.push({ name: "Restaurants" });
                                });
                            });
                        })
                        .catch(() => {
                            _this.$swal.fire("Error", "Please enter correct restaurant details.", "error");
                        })
                        .then(() => {
                            _this.saving_text = "Save"; 
                            _this.saving_load = false;
                        });
                }
        },
        add_restaurant2: function () {
            let _this = this;
            _this.saving_load2 ||
                ((_this.saving_text2 = ""),
                (_this.saving_load2 = true),
                _this.axios
                    .post(`${BASE_URL}createrestaurant`, {
                        restaurant_name: _this.form_data.restaurant_name,
                        address: _this.form_data.address,
                        type_id: _this.form_data.restaurant_type,
                        phone: _this.form_data.phone,
                        city: "Select a city or town" != _this.form_data.city ? _this.form_data.city : "",
                        user_id: _this.auth_user_data.id,
                        opening_time: _this.form_data.opening_time,
                        closing_time: _this.form_data.closing_time,
                    })
                    .then(function () {
                        _this.fetch_vendor_restaurants().then(() => {
                            _this.form_data.restaurant_name = "";
                            _this.form_data.address = "";
                            _this.form_data.restaurant_type = [];
                            _this.form_data.phone = "";
                            _this.form_data.city = "Select a city or town";
                            _this.form_data.opening_time = "00";
                            _this.form_data.closing_time = "00";
                            _this.$swal.fire("Great!", "Restaurant creation successful.", "success").then(() => {
                                document.querySelector('.scroll-to-top').click();
                            });
                        });
                    })
                    .catch(function () {
                        _this.$swal.fire("Error", "Please enter correct restaurant details.", "error");
                    })
                    .then(function () {
                        (_this.saving_text2 = "Save and add new"), (_this.saving_load2 = false);
                    }));
        },
    },
    mounted() {
        document.title = 'Add Restaurant - Chops';
    }
}
</script>

<style scoped>
.add-mb-5 {
    margin-bottom: 5rem;
}
</style>