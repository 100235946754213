import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import swalPlugin from "./plugins/VueSweetalert2";
import IdleVue from 'idle-vue'
 
const eventsHub = new Vue()
 
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000 // 30 minutes
})

Vue.use(VueAxios, axios);

Vue.use(swalPlugin);

Vue.filter("number_with_commas", function (t) {
  return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

export const BASE_URL = "https://chops.ijeapp.com/api/";
export const ASSETS_URL = "https://chops.ijeapp.com";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
