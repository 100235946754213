<template>
  <!-- Navigation-->
  <nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
      <router-link class="navbar-brand" :to="{ name: 'Home' }">
        <img src="/assets/logo-test-2.png" alt="" width="71" height="36">
      </router-link>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
              <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                  <router-link class="nav-link" :to="{ name: 'Home' }">
                      <i class="fa fa-fw fa-dashboard"></i>
                      <span class="nav-link-text">Dashboard</span>
                  </router-link>
              </li>
              <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                  <router-link class="nav-link" :to="{ name: 'Restaurants' }">
                      <i class="fa fa-fw fa-store-alt"></i>
                      <span class="nav-link-text">Restaurants</span>
                  </router-link>
              </li>
              <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                  <router-link class="nav-link" :to="{ name: 'Menus' }">
                      <i class="fa fa-fw fa-utensils"></i>
                      <span class="nav-link-text">Menus</span>
                  </router-link>
              </li>
              <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                  <router-link class="nav-link" :to="{ name: 'Orders' }">
                      <i class="fa fa-fw fa-shopping-cart"></i>
                      <span class="nav-link-text">Orders</span>
                  </router-link>
              </li>
              <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                  <router-link class="nav-link" :to="{ name: 'UserProfile' }">
                      <i class="fa fa-fw fa-user"></i>
                      <span class="nav-link-text">My Profile</span>
                  </router-link>
              </li>
          </ul>
          <ul class="navbar-nav sidenav-toggler">
              <li class="nav-item">
                  <a class="nav-link text-center" id="sidenavToggler">
                      <i class="fa fa-fw fa-angle-left"></i>
                  </a>
              </li>
          </ul>
          <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                  <a class="nav-link" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-fw fa-sign-out" style="margin-right: 4px;"></i>Logout</a>
              </li>
          </ul>
      </div>
  </nav>
  <!-- /Navigation-->
</template>

<script>
export default {
  mounted() {
    let admin_js = document.createElement('script')
    admin_js.setAttribute('src', '/assets/admin/js/admin.js')
    document.head.appendChild(admin_js)

    // let jQuery_js = document.createElement('script')
    // jQuery_js.setAttribute('src', '/assets/admin/js/jquery.min.js')
    // document.head.appendChild(jQuery_js)

    let bootstrap_js = document.createElement('script')
    bootstrap_js.setAttribute('src', '/assets/admin/js/bootstrap.bundle.min.js')
    document.head.appendChild(bootstrap_js)
  }
}
</script>

<style>
.navbar-sidenav-tooltip {
    display: none !important;
}
</style>

<style scoped>
li a span.nav-link-text {
    margin-left: 7px;
}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
