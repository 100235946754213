<template>
    <div>
        <figure>
            <a href="https://chops.ng" target="_blank">
                <img src="/assets/img/logo_sticky.png" alt="" />
            </a>
        </figure>
        <form autocomplete="off">
            <div class="form-group">
                <input v-model="form_data.email" type="email" placeholder="Email" class="form-control" />
                <i class="icon_mail_alt"></i>
            </div>
            <button @click.prevent="forgot_password_request()" class="btn_1 gradient full-width">
                <span>
                    {{ saving_text }}
                    <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : ''"></i>
                </span>
            </button>
            <div class="text-center mt-2">
                <small>
                    Go back to <strong> <router-link :to="{ name: 'Login' }" class="">Sign In</router-link></strong>
                </small>
            </div>
        </form>
    </div>
</template>

<script>
    import { BASE_URL } from "@/main";
    export default {
        data() {
            return {
                form_data: {
                    email: "",
                },
                saving_text: "Reset Password!",
                saving_load: false,
            };
        },
        methods: {
            forgot_password_request() {
                let _this = this;
                if (!_this.form_data.email) {
                    _this.$swal.fire({
                        title: "Error", 
                        text: "Please enter an email address.", 
                        icon: "error"
                    });
                    return;
                }
                if (!_this.saving_load) {
                    _this.saving_text = "";
                    _this.saving_load = true;
                    _this.axios.post(`${BASE_URL}forgotpassword`, {
                            email: _this.form_data.email
                        })
                        .then(() => {
                            _this.$swal.fire({
                                title: "Great!", 
                                text: "Please check your email for your password reset link.", 
                                icon: "success"
                            }).then(() => {
                                _this.$router.push({ name: 'Home' })
                            })
                        })
                        .catch(() => {
                            _this.$swal.fire({
                                title: "Error", 
                                text: "Please enter a correct/valid email address.", 
                                icon: "error"
                            });
                        })
                        .then(() => {
                            _this.saving_text = "Reset Password!";
                            _this.saving_load = false;
                        });
                }
            }
        },
        mounted() {
            document.title = 'Forgot Password - Chops';
        }
    };
</script>

<style></style>
