import Vue from 'vue';
import VueRouter from 'vue-router';
import Error404 from '../views/Error404';
import Home from '../views/Home';
import Restaurants from '../views/Restaurants';
import Menus from '../views/Menus';
import Orders from '../views/Orders';
import UserProfile from '../views/UserProfile';
import AddRestaurant from '../views/AddRestaurant';
import AddMenu from '../views/AddMenu';
import Register from '../views/Register';
import Login from '../views/Login';
import ForgotPassword from '../views/ForgotPassword';
import RecoverPassword from '../views/RecoverPassword';
import store from '../store';

Vue.use(VueRouter)

const LOGGED_IN = (to, from, next) => {
  if (store.getters.logged_in) {
    if(document.querySelector('.show') != null && screen.width < 1000) {
        document.querySelector('.navbar-toggler').click();
    }
    store.commit('ORDERS_NOT_LOADED');
    store.commit('RESTAURANTS_NOT_LOADED');
    store.commit('MENUS_NOT_LOADED');
    return next();
  }
  return next({ name: 'Login' });
};

const NOT_LOGGED_IN = (to, from, next) => {
  if (!store.getters.logged_in) {
    return next();
  }
  return next({ name: 'Home' });
};

const routes = [
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/menus',
    name: 'Menus',
    component: Menus,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: Restaurants,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/userprofile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/addrestaurant',
    name: 'AddRestaurant',
    component: AddRestaurant,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/addmenu',
    name: 'AddMenu',
    component: AddMenu,
    beforeEnter: LOGGED_IN
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: NOT_LOGGED_IN
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: NOT_LOGGED_IN
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: NOT_LOGGED_IN
  },
  {
    path: '/recoverpassword/:recovery_code',
    name: 'RecoverPassword',
    component: RecoverPassword,
    beforeEnter: NOT_LOGGED_IN
  },
  {
    path: '*',
    name: '404',
    component: Error404,
    beforeEnter: LOGGED_IN
  }
]

const router = new VueRouter({
  scrollBehavior: function() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes
})

export default router
