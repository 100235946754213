<template>
    <footer class="sticky-footer pt-5">
        <div class="container">
            <div class="text-center">
                <small>Copyright &copy; Chops {{ current_year }}</small>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters([
            'current_year'
        ])
    }
}
</script>

<style>

</style>