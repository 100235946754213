<template>
  <div class="container-fluid">
        <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">My Profile</li>
            </ol>
        <!-- Example DataTables Card-->
        <div class="box_general padding_bottom">
            <div class="header_box version_2">
                <h2><i class="fas fa-utensils"></i>Profile details</h2>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Name</label>
                        <input 
                        :disabled="true"
                        :placeholder="auth_user_data.name || ''"
                        type="text" 
                        class="form-control">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Email</label>
                        <input 
                        :disabled="true"
                        :placeholder="auth_user_data.email || ''"
                        type="text" 
                        class="form-control">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Phone</label>
                        <input
                        :disabled="true" 
                        :placeholder="auth_user_data.phone || ''"
                        type="text" 
                        class="form-control">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="box_general padding_bottom">
                    <div class="header_box version_2">
                        <h2><i class="fas fa-user-edit"></i>Change details</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input 
                                v-model="form_data.name"
                                type="text" 
                                class="form-control">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Phone</label>
                                <input 
                                v-model="form_data.phone"
                                type="text" 
                                class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box_general padding_bottom">
                    <div class="header_box version_2">
                        <h2><i class="fa fa-envelope"></i>Change email</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>New email</label>
                                <input 
                                v-model="form_data.email"
                                type="email" 
                                class="form-control">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Confirm new email</label>
                                <input 
                                v-model="form_data.confirm_email"
                                type="email" 
                                class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box_general padding_bottom">
                    <div class="header_box version_2">
                        <h2><i class="fa fa-lock"></i>Change password</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>New password</label>
                                <input 
                                v-model="form_data.password"
                                type="password" 
                                class="form-control">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Confirm new password</label>
                                <input 
                                v-model="form_data.confirm_password"
                                type="password" 
                                class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="add-mb-5">
            <button @click.prevent="add_menu()" class="text-white btn_1 medium"> 
                {{ saving_text }} 
                <i class="fad fa-spinner fa-spin" :style="!saving_load ? 'display: none;' : 'width: 40px;'"></i>
            </button>
        </p>
    </div>
    <!-- /container-fluid-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL } from '@/main';
export default {
    data() {
        return {
            form_data: { 
                id: "", 
                name: "", 
                phone: "", 
                email: "", 
                confirm_email: "", 
                password: "", 
                confirm_password: ""
            },
            saving_text: "Save",
            saving_load: false
        }
    },
    computed: {
        ...mapGetters([
            'auth_user_data',
        ])
    },
    methods: {
        ...mapActions([
            'update_profile',
        ]),
        add_menu: function () {
            let _this = this;
            if (_this.form_data.password != _this.form_data.confirm_password) {
                _this.$swal.fire("Uh-oh", "The new password and password confirmation fields don't match!", "error");
                return;
            }
            if (_this.form_data.email != _this.form_data.confirm_email) {
                _this.$swal.fire("Uh-oh", "The new email and email confirmation fields don't match!", "error");
                return;
            }
            _this.saving_load ||
                ((_this.saving_text = ""),
                (_this.saving_load = true),
                _this.axios
                    .post(`${BASE_URL}editvendorprofile`, {
                        id: _this.auth_user_data.id,
                        name: _this.form_data.name,
                        phone: _this.form_data.phone || null,
                        email: _this.form_data.email,
                        password: _this.form_data.password
                    })
                    .then(function (response) {
                        _this.update_profile(response.data.user);
                        _this.form_data.name = _this.auth_user_data.name;
                        _this.form_data.phone = _this.auth_user_data.phone;
                        _this.form_data.email = "";
                        _this.form_data.confirm_email = "";
                        _this.form_data.password = "";
                        _this.form_data.confirm_password = "";
                        _this.$swal.fire("Great!", "Your profile has been updated successfully.", "success");
                    })
                    .catch(function () {
                        _this.$swal.fire("Error", "Your profile couldn't be updated at this time.", "error");
                    })
                    .then(function () {
                        (_this.saving_text = "Save"), (_this.saving_load = false);
                    }));
        },
    },
    mounted() {
        document.title = 'User Profile - Chops';
        let _this = this;
        _this.form_data.name = _this.auth_user_data.name;
        _this.form_data.phone = _this.auth_user_data.phone;
    }
}
</script>

<style scoped>
.add-mb-5 {
    margin-bottom: 5rem;
}
</style>